@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-black bg-gradient-to-l from-gray-700 to-[#000000];
  }
}

div button {
  margin: 10px;
  padding: 10px;
  border: 1px solid white;
  background-color: #1976d2;
  border-radius: 10px;
}

div label {
  /* margin: 10px; */
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
}

table {
  border-collapse: collapse;
  /* width: 100%; */
  padding: 10px;
  border: 1px solid #ddd;
  margin: 20px 10px;
}

th,
td {
  text-align: left;
  padding: 10px;
}

.card {
  max-width: 300px;
  margin: 20px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  object-fit: contain;
}

.card img {
  height: 100%;
  width: 100%;
}

.nftcontainer {
  width: 100%;
  display: flex;
  padding: 10px;
}
.marginR {
  padding-right: 10px;
}
.nft {
}

@layer components {
  .buttonB {
    @apply bg-teal-600;
  }
}
/* .buttonB, .bg-indigo-600{ */
/*   background-color: bg-teal-600; */
/* } */
